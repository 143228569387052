@import url(https://fonts.googleapis.com/css?family=Tenor+Sans);

html, body {
  background: #0071bc;
  font-family: "Tenor Sans", sans-serif;
  height: 100%;
  min-height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.login {
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
  }
r
.logo {
  margin:30px;
}

.logo img{
  width: 230px;
}

.card {
  min-width: 340px;
  width: 600px;
}

.card-header {
  background-color: #fff;
}

.h2-logado {
  font: 1.1em/38px "Tenor Sans", sans-serif;
  color: #81aac9;
  margin:20px;
}

.login-heading {
  font: 1.6em/38px "Tenor Sans", sans-serif;
  color: #81aac9;
  margin:20px;
}

.msgFrame{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

li{
  color: #2c3e50;
  font-size: 12px;
  line-height: 30px;
  text-align: justify;
  letter-spacing: 1px;
}

.footer {
  font-size: 12px;
}
